import { Box } from "@chakra-ui/react"
import { range } from "lodash-es"
import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { PageHeading } from "../../components/layout/PageHeading"
import { TypingTest2 } from "../../components/typing-test-2/TypingTest2"
import { Layout2 } from "../../components/vlayout/Layout2"

export default function Nav2Page() {
  return (
    <DebugLayout title="Nav2" flush>
      <Layout2>
        <PageHeading>Typing Test</PageHeading>
        <TypingTest2 />
        {/* {range(0, 10).map(i => (
          <Box fontSize="lg" key={i}>
            laboris amet mollit cillum ipsum. Voluptate enim reprehenderit
            cillum Ullamco veniam fugiat reprehenderit qui quis nulla dolore
            duis anim proident mollit dolore ut aliquip cillum ad deserunt in
            cupidatat Lorem. Veniam ullamco enim aute eiusmod incididunt quis
            esse deserunt veniam aliquip est proident sunt minim. Velit culpa
            tempor sint nisi.
          </Box>
        ))} */}
      </Layout2>
    </DebugLayout>
  )
}
